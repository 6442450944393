import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { AssessmentApplicationService } from '@app/core';
import { notNull, safeEmpty } from '@app/shared';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'app-modal-assessments-report',
  templateUrl: './modal-assessments-report.component.html',
})
export class ModalAssessmentsReportComponent {
  public application$: Observable<any>;

  constructor(@Inject(DIALOG_DATA) public data: string, private _gdaReportService: AssessmentApplicationService) {
    this.loadData();
  }

  public onRefresh() {
    this.loadData();
  }

  private loadData() {
    this.application$ = this._gdaReportService.getGeneralData(this.data).pipe(take(1), safeEmpty(), notNull());
  }
}
