export enum AssessmentStatusAttemptEnum {
  Init,
  InProgress,
  Wait,
  Finish,
  WaitDateResult,
  Review,
  ModelUndefined,
  ResponseCardInvalid,
}
