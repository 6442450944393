import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService extends CrudService<any> {
  private _url = `${portalConst.api.academic}/v1/`;

  constructor(api: ApiService) {
    super(api, `${portalConst.api.academic}/v1/occurrences`);
  }

  public filterEducational(data: any) {
    return this.api.get<any[]>(`${this._url}appointments/educational/filter`, data);
  }

  public sendEmail(data: any) {
    return this.api.get(`${this._url}appointments/email`, data);
  }

  public print(data: any) {
    const query = this.api.resolveParams(data).toString();
    return `${this._url}report/letter-occurrence?${query}`;
  }

  public printBatchOccurrences(req: any): Observable<any> {
    return this.api.post(`${this._url}report/print-batch`, req);
  }

  public printGeneral(data: any) {
    window.open(
      `${this._url}report/general?occurrence_ids=${data.occurrence_ids}&notification_ids=${data.notification_ids}&token=${data.token}`,
      '_blank',
    );
  }

  public printDeliberative(data: any): string {
    return `${this._url}deliberative-form/print?entity_id=${data.entity_id}&students_id=${data.students_id}&bimester_id=${data.bimester_id}&group_id=${data.group_id}&teacher=${data.teacher}&retention=${data.retention}&hispanic=${data.hispanic}&recomendations_id=${data.recomendations_id}&token=${data.token}`;
  }

  public getRecomendations(): Observable<any> {
    return this.api.list(`${this._url}recomendations`);
  }

  public getOccurrenceRecomendations(): Observable<any> {
    return this.api.list(`${portalConst.api.academic}/v1/appointments/recomendations`);
  }

  public getBimesters(entityId: number): Observable<any> {
    return this.api.list(`${this._url}entity-bimester/${entityId}`);
  }

  public studentEducationalOccurrences(req: any): Observable<any> {
    return this.api.list(`${this._url}student-occurrences`, req);
  }

  public updateOccurrencesOnScreen(req: any): Observable<any> {
    return this.api.list(`${this._url}update-screen`, req);
  }

  public updateAppointment(id: number, data: any): Observable<any> {
    return this.api.put(`${this._url}appointments/update/${id}`, data);
  }
}
