import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, startWith } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LoadingCpbComponent } from '../loading-cpb/loading-cpb.component';
import { LoadingWeeklyComponent } from '../loading-weekly/loading-weekly.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoadingEclassComponent } from '../loading-eclass/loading-eclass.component';
import { LoadingAcesComponent } from '../loading-acesclass/loading-acesclass.component';

export enum Loading {
  Cpb,
  Eclass,
  Weekly,
  Aces,
}

export const Products = {
  [Loading.Cpb]: LoadingCpbComponent,
  [Loading.Eclass]: LoadingEclassComponent,
  [Loading.Weekly]: LoadingWeeklyComponent,
  [Loading.Aces]: LoadingAcesComponent,
};

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  public loading$: Observable<ComponentPortal<any>>;

  constructor(private store: Store<AppState>, private destroyRef: DestroyRef) {
    const isExternal$ = this.store.select(AppSelectors.isExternal).pipe(notNull());
    const isExternalHispanic$ = this.store.select(AppSelectors.isExternalHispanic).pipe(notNull());

    this.loading$ = combineLatest([isExternal$, isExternalHispanic$]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([isExternal, isExternalHispanic]) => {
        if (isExternalHispanic) {
          return Loading.Aces;
        }
        if (isExternal) {
          return Loading.Weekly;
        }
        return Loading.Eclass;
      }),
      startWith(this.startLoading(new URL(window.location.href).hostname)),
      distinctUntilChanged(),
      map((loading) => Products[loading]),
      map((component) => new ComponentPortal(component)),
    );
  }

  private startLoading(hostname: string): Loading {
    if (hostname.includes('sistemainterativo.com.br')) {
      return Loading.Weekly;
    }
    if (hostname.includes('cpbedu.me')) {
      return Loading.Cpb;
    }
    if (hostname.includes('acesclass.org')) {
      return Loading.Aces;
    }
    return Loading.Eclass;
  }
}
