export interface StudentGroupSummary {
  id: number;
  aluno_id: number;
  turma_id: number;
  descricao: string;
}
export interface StudentSummary {
  group_by: string;
  id: number;
  name: string;
  photo: string;
  pcd: number;
  group_id: number;
  responsible: ResponsableSummary[];
  student_groups: StudentGroupSummary[];
}

export interface ResponsableSummary {
  id: number;
  nome: string;
  celular: string;
  email_sse: string;
  usuario: any;
}
