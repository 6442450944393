import { Component, ElementRef, Inject } from '@angular/core';
import { AssessmentAnnulTypeEnum, AssessmentQuestionType } from '@app/core/models';
import { Popover, POPOVER_DATA, PopoverRef, Strategy } from '@app/shared/modules/template/components/popover';
import { PopoverAnnulQuestionComponent } from '../../../modal-evaluation-report/components/popover-annul-question/popover-annul-question.component';
import { AnswerOptionEnum } from './answer-option.enum';

@Component({
  selector: 'app-popover-answer-options',
  templateUrl: './popover-answer-options.component.html',
})
export class PopoverAnswerOptionsComponent {
  public readonly assessmentQuestionType = AssessmentQuestionType;

  constructor(
    @Inject(POPOVER_DATA) public data: any,
    private _popoverRef: PopoverRef<any>,
    private _popover: Popover,
  ) {}

  public onOpenAnullQuestion(elementRef: ElementRef) {
    this._popover
      .open(PopoverAnnulQuestionComponent, {
        positionStrategy: [Strategy.LEFT],
        elementRef,
        data: {
          ...this.data,
          type: AssessmentAnnulTypeEnum.Answer,
        },
      })
      .closed.subscribe((data) => this._popoverRef.close({ action: AnswerOptionEnum.Anull, data }));
  }

  public onCleanQuestionAlternative() {
    this._popoverRef.close({ action: AnswerOptionEnum.Clean, data: this.data.question.uuid });
  }
}
