import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AcademicAttendanceReportService extends CrudService<any> {
  private _url = `${portalConst.api.academic}/v1/attendance-reports`;

  constructor(api: ApiService) {
    super(api, `${portalConst.api.academic}/v1/attendance-reports`);
  }

  public listAttendanceReports(student_id: number, req: any): Observable<any> {
    return this.api.get(`${this._url}/${student_id}`, req);
  }

  public getResponsibles(student_id: number): Observable<any> {
    return this.api.get(`${this._url}/responsibles/${student_id}`);
  }

  public saveAttendance(req: any): Observable<any> {
    return this.api.post(`${this._url}`, req);
  }

  public deleteAttendanceReport(reportId: number) {
    return this.api.delete(`${this._url}/${reportId}`);
  }

  public print(data: any): string {
    return `${this._url}/print?reports_id=${data.ids}&student_id=${data.student_id}&token=${data.token}`;
  }

  public dashboardReports(req: any): Observable<any> {
    return this.api.get(`${this._url}/reports-dashboard`, req);
  }

  public sendEmail(req: any): Observable<any> {
    return this.api.post(`${this._url}/send-email`, req);
  }
}
