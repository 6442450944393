import { NgModule, Optional, SkipSelf } from '@angular/core';

import { MenuProviders } from '../menu';
import { AcademicAttendanceReportService } from './academic-attendance-report.service';
import { AcademicStudentService } from './academic-student.service';
import { ActivityService } from './activity.service';
import { AlertService } from './alert.service';
import { AnswerService } from './answers.service';
import { ApiService } from './api.service';
import { BooksService } from './books.service';
import { ContentsCategoriesService } from './contents-categories.service';
import { CoreService } from './core.service';
import { DailyService } from './daily.service';
import { DataEnrollmentService } from './data-enrollment.service';
import { DefaultService } from './default.service';
import { DriveService } from './drive.service';
import { EclassFilterService } from './eclass-filter.service';
import { EnrollmentService } from './enrollment.service';
import { GamesService } from './games.service';
import { IntegrationService } from './integration.service';
import { LayoutTemplateService } from './layout-template.service';
import { LevelEducationService } from './level-education.service';
import { LoadingService } from './loading.service';
import { LoggedUserService } from './logged-user.service';
import { LoginService } from './login.service';
import { MenuService } from './menu.service';
import { NotificationHistoryService } from './notification-history.service';
import { PoliciesService } from './policies.service';
import { ReaderLevelService } from './reader-level.service';
import { SidenavService } from './sidenav.service';
import { StatisticService } from './statistic.service';
import { ThemeService } from './theme.service';
import { UploadService } from './upload.service';
import { UserService } from './user.service';

@NgModule({
  providers: [
    ApiService,
    LoadingService,
    CoreService,
    UserService,
    LoginService,
    LoggedUserService,
    ThemeService,
    SidenavService,
    PoliciesService,
    BooksService,
    LevelEducationService,
    ReaderLevelService,
    DefaultService,
    IntegrationService,
    AlertService,
    DriveService,
    AcademicStudentService,
    EnrollmentService,
    DailyService,
    ActivityService,
    AnswerService,
    ContentsCategoriesService,
    LayoutTemplateService,
    GamesService,
    MenuService,
    MenuProviders,
    DataEnrollmentService,
    EclassFilterService,
    StatisticService,
    NotificationHistoryService,
    UploadService,
    AcademicAttendanceReportService,
  ],
})
export class CoreServiceModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreServiceModule) {
    if (parentModule) {
      throw new Error('CoreServicesModule has already been loaded. Import CoreServicesModule in the AppModule only.');
    }
  }
}
