import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { I18nService, NotificationHistoryService } from '@app/core';
import { Language, LanguageEnum, NOTIFICATION_REFERENCE, NotificationData } from '@app/core/models';
import { LanguageService } from '@app/core/services/language.service';
import { WebSocketService } from '@app/core/services/web-socket.service';
import { PopoverNotificationService } from '@app/modules/notification';
import { ModalServicesService } from '@app/modules/services';
import { Popover, PopoverRef, Strategy } from '@app/shared/modules/template/components/popover';
import {
  Checker,
  isExternal,
  isExternalHispanic,
  notNull,
  safeEmpty,
  safeEmptyList,
  userPhoto,
} from '@app/shared/utils';
import { NotificationUtil } from '@app/shared/utils/notification';
import { AppActions, AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { toLower } from 'lodash';
import { Observable, merge, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { PopoverProfileComponent } from '../popover-profile/popover-profile.component';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly isDev = !environment.production;
  @Input() public showToogle = false;
  @Input() public toogleStatus = false;
  @Output() public toogle = new EventEmitter();
  public isExternal$: Observable<boolean>;
  public isExternalHispanic$: Observable<boolean>;
  public store$: Observable<AppState>;
  public language$: Observable<Language[]>;
  public userLanguage$: Observable<string>;
  private _profile: PopoverRef<any>;
  private _notification: PopoverRef<any>;
  public hasNotification$: Observable<boolean>;
  public pulseNotification = false;
  @ViewChild('menu') private viewChildMenu: ElementRef;

  constructor(
    private popover: Popover,
    private destroy: DestroyRef,
    private store: Store<AppState>,
    private _store: Store<AppState>,
    private i18nService: I18nService,
    private service: ModalServicesService,
    private languageService: LanguageService,
    private webSocketService: WebSocketService<NotificationData>,
    private _translateService: TranslateService,
    private popoverNotification: PopoverNotificationService,
    private _notificationHistoryService: NotificationHistoryService,
  ) {}

  public ngOnInit() {
    this.loadData();
  }

  public ngOnDestroy() {
    this._profile?.close();
    this._notification?.close();
  }

  public ngAfterViewInit() {
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        takeUntilDestroyed(this.destroy),
        notNull(),
        filter(() => !!this.viewChildMenu),
        map(({ entidade_id }) => !entidade_id),
        distinctUntilChanged(),
        notNull(),
      )
      .subscribe(() => this.onMenu(this.viewChildMenu));
  }

  public onToogle() {
    this.toogle.emit(!this.toogleStatus);
  }

  public onService() {
    this.service.open();
  }

  public onNotification(elementRef: ElementRef) {
    this.pulseNotification = false;
    this.popoverNotification.open(elementRef);
  }

  public onMenu(elementRef: ElementRef) {
    this._profile = this.popover.open(PopoverProfileComponent, {
      positionStrategy: [Strategy.BOTTOM_END],
      elementRef,
    });
  }

  public icon(status: boolean): string {
    if (status) {
      return 'assets/icons/icon-align-collapse.svg';
    }
    return 'assets/icons/icon-align-left.svg';
  }

  public dependent(store: AppState): any {
    const dependents = store.dependentes || [];
    const dependent = dependents.find((dependent) => dependent.aluno_id === store.usuario_ativo.aluno_id);

    if (dependent) {
      const turma =
        store.usuario_ativo.secretaria_sistema_id === 3 ? dependent.turma?.descricao : dependent.turma?.cod_turma;
      return { name: dependent.name, lastName: dependent.sobrenome, serie: turma };
    }

    if (!!store.usuario_ativo.aluno_id) {
      const turma = store.usuario_ativo.secretaria_sistema_id === 3 ? store.turma?.descricao : store.turma?.cod_turma;
      return { name: store.nome, lastName: store.sobrenome, serie: turma };
    }
    return null;
  }

  public onLang(lang: any, current: any) {
    if (!this.isEquals(lang, current)) {
      this.languageService
        .updateLanguage({ lang_id: lang.id })
        .pipe(safeEmpty())
        .subscribe((res) => {
          this.store.dispatch(AppActions.LanguageReducer(res));
          this.i18nService.change(res.sigla);
        });
    }
  }

  public isEquals(language: Language, lang: string): boolean {
    return toLower(language.sigla) === toLower(lang);
  }

  private loadData() {
    this.loadExternal();
    this.loadStore();
    this.loadBilingual();
    this.loadNotification();
  }

  private loadExternal() {
    this.isExternal$ = this.store
      .select(AppSelectors.isExternal)
      .pipe(takeUntilDestroyed(this.destroy), startWith(isExternal()));

    this.isExternalHispanic$ = this.store
      .select(AppSelectors.isExternalHispanic)
      .pipe(takeUntilDestroyed(this.destroy), startWith(isExternalHispanic()));
  }

  private loadStore() {
    this.store$ = this.store.select(AppSelectors.appFeature).pipe(
      takeUntilDestroyed(this.destroy),
      filter((res) => !!res.usuario_ativo),
      map((store) => ({ ...store, photo: userPhoto(store.photo) })),
    );
  }

  private loadBilingual() {
    this.language$ = this.store.select(AppSelectors.appFeature).pipe(
      takeUntilDestroyed(this.destroy),
      notNull(),
      map(({ funcao_padrao, usuario_ativo }) => {
        if (!!usuario_ativo?.escola_bilingue) {
          return Checker.isEmployee(funcao_padrao) || !!usuario_ativo?.turma_bilingue;
        }
        return false;
      }),
      switchMap((res) => {
        if (res) {
          return this.languageService
            .index({ lang_ids: [LanguageEnum.Portugues, LanguageEnum.English] })
            .pipe(safeEmptyList());
        }
        return of([]);
      }),
      map((res) =>
        res.map((lang) => ({
          ...lang,
          img_flag: `assets/images/flags/lang_${toLower(lang.sigla)}.svg`,
        })),
      ),
    );
    this.userLanguage$ = this.store.select(AppSelectors.language).pipe(takeUntilDestroyed(this.destroy), notNull());
  }

  private loadNotification() {
    const unread$ = this._notificationHistoryService.unread();
    const socket$ = this.webSocketService.message$.pipe(
      takeUntilDestroyed(this.destroy),
      catchError(() => of({ data: null })),
      map((res) => res?.data),
      notNull(),
      tap((data) => NotificationUtil.browserNotify.subscribe(() => this.notify(data))),
      tap(() => (this.pulseNotification = true)),
      map((data) => !!data),
    );
    this.hasNotification$ = merge(unread$, socket$).pipe(takeUntilDestroyed(this.destroy));
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(
        takeUntilDestroyed(this.destroy),
        notNull(),
        map(({ usuario_id }) => usuario_id),
        distinctUntilChanged(),
      )
      .subscribe(() => this._notificationHistoryService.refresh());
  }

  private notify(data: NotificationData) {
    this.isExternal$
      .pipe(
        map((isExternal) => (isExternal ? 'logo-weekly.svg' : 'logo-cla.svg')),
        map((icon) => ({
          title: this.title(data),
          body: this.body(data),
          icon: `assets/images/logos/${icon}`,
        })),
      )
      .subscribe(({ title, body, icon }) => new Notification(title, { body, icon }));
  }

  private title(data: NotificationData): string {
    const reference = NOTIFICATION_REFERENCE[data.extra.reference.type];
    const title = !!reference ? reference?.title : 'notificacoes.titulo.default';
    return this._translateService.instant(title);
  }

  private body(data: NotificationData): string {
    let text = this._translateService.instant(`notificacoes.${data.message}`, data.extra.reference);
    if (!!data.extra.reference?.isLate) {
      text = this._translateService.instant('notificacoes.answer.named-late', data.extra.reference);
    }

    return text;
  }
}
