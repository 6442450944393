import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppointmentTypeService extends CrudService<any> {
  private _url = `${portalConst.api.academic}/v1`;

  constructor(api: ApiService) {
    super(api, `${portalConst.api.academic}/v1/occurrence-type`);
  }

  public groupedByClassification(req: any): Observable<any> {
    return this.api.list(`${this._url}/occurrence-classification`, req);
  }
}
