<ng-container *ngIf="data$ | async as entities; else elseTemplate">
  <div class="flex h-[80vh] w-full flex-col gap-8">
    <div class="flex h-1/2 w-full flex-col">
      <p class="py-6 text-xl font-semibold">{{ 'gestor-avaliacoes.percentual-acerto-disciplina' | translate }}</p>
      <div app-tab-content class="grid h-full gap-4 overflow-auto border-t-2 border-t-base-mantis-500">
        <table app-table>
          <thead>
            <tr>
              <th app-table-cell translate class="min-w-[94px]">{{ 'gestor-avaliacoes.unidade' | translate }}</th>
              <ng-container *ngFor="let subject of entities[0]?.subjects">
                <th app-table-cell translate class="min-w-[94px]">{{ getSubjectDescription(subject.subject_id) }}</th>
              </ng-container>
              <th app-table-cell translate>{{ 'gestor-avaliacoes.participantes' | translate }}</th>
              <th app-table-cell translate>{{ 'gestor-avaliacoes.participantes-esperados' | translate }}</th>
              <th app-table-cell translate>{{ 'gestor-avaliacoes.percentual-participantes' | translate }}</th>
            </tr>
          </thead>
          <tbody app-table-body>
            <tr *ngFor="let entity of entities">
              <td app-table-cell class="text-center">
                {{ entity.entity ? entity.entity : entity.union || entity.association }}
              </td>
              <ng-container *ngFor="let subject of entity.subjects">
                <td app-table-cell class="text-center">{{ subject.percentage_hit | number : '1.2-2' }} %</td>
              </ng-container>
              <td app-table-cell class="text-center">{{ entity.student_quantity }}</td>
              <td app-table-cell class="text-center">{{ entity.total_student }}</td>
              <td app-table-cell class="text-center">{{ entity.percentage }} %</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex h-1/2 w-full flex-col">
      <p class="py-6 text-xl font-semibold">{{ 'gestor-avaliacoes.percentual-acerto-habilidade' | translate }}</p>
      <div app-tab-content class="grid h-full gap-4 overflow-auto border-t-2 border-t-base-mantis-500">
        <table app-table>
          <thead>
            <tr>
              <th app-table-cell translate class="min-w-[250px]">{{ 'gestor-avaliacoes.unidade' | translate }}</th>
              <ng-container *ngFor="let category of entities[0]?.categories">
                <th app-table-cell translate class="min-w-[94px]">
                  {{ getCategoryDescription(category.category_id) }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody app-table-body>
            <tr *ngFor="let entity of entities">
              <td app-table-cell class="text-center">
                {{ entity.entity ? entity.entity : entity.union || entity.association }}
              </td>
              <ng-container *ngFor="let categories of entity.categories">
                <td app-table-cell class="text-center">{{ categories.percentage_hit | number : '1.2-2' }} %</td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <app-card class="mt-5">
    <div app-card-content class="h-[200px]">
      <app-skeleton *ngIf="loading$ | async" class="h-full w-full"></app-skeleton>
    </div>
  </app-card>
</ng-template>
