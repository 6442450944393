import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlertService, AssessmentApplicationService, AssessmentAttemptService, NotificationService } from '@app/core';
import {
  AssessmentAnnulTypeEnum,
  AssessmentApplicationMode,
  AssessmentStatusAttemptEnum,
  AssessmentWeightType,
} from '@app/core/models';
import { notNull, safeEmpty, safeEmptyList, selectedEntityActive } from '@app/shared';
import { KeyboardShortcutsService } from '@app/shared/modules/keyboard-shortcuts/keyboard-shortcuts.service';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { SidenavMode } from '@app/shared/modules/template/components/sidenav/sidenav';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first, isNil } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, filter, finalize, map, of, switchMap, take, tap } from 'rxjs';
import { PopoverAnnulQuestionComponent } from '../../../modal-evaluation-report/components/popover-annul-question/popover-annul-question.component';
import { AnswerOptionEnum } from './answer-option.enum';
import { PopoverAnswerOptionsComponent } from './popover-answer-options.component';

interface QuestionsByModelo {
  modo_rand: number;
  questions: any[];
}
@Component({
  selector: 'app-modal-body-answers-audit',
  templateUrl: './modal-body-answers-audit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBodyAnswersAuditComponent implements OnDestroy {
  public readonly badgeStyle = {
    background: 'bg-red-100 text-black hover:bg-red-300 hover:text-white',
    active: 'bg-red-500 text-white',
  };

  @ViewChild('pinchZoom') public pinchZoomComponent;

  public readonly strategy = Strategy;
  public readonly mode = SidenavMode;
  public readonly assessmentWeightType = AssessmentWeightType;
  public readonly assessmentApplicationMode = AssessmentApplicationMode;
  public readonly assessmentStatusAttemptEnum = AssessmentStatusAttemptEnum;
  public form: FormGroup;
  public dataStudentFilter$: Observable<any[]>;
  public data$ = new BehaviorSubject<any>(undefined);
  public dataStudents$ = new BehaviorSubject<any[]>(undefined);
  public selectedUser$ = new BehaviorSubject<any>(undefined);
  public aplication$ = new BehaviorSubject<any>(undefined);
  public loading$ = new BehaviorSubject<boolean>(false);
  public loadingQuestions$ = new BehaviorSubject<boolean>(false);
  public loadingQuestionAlternative$ = new BehaviorSubject<string>(undefined);
  public dataViewer$ = new BehaviorSubject<string>(undefined);
  public pendingCtrl = new FormControl(false);
  public dataStudentCtrl = new FormControl(undefined);
  public scales = [
    { value: 0, shortcut: 'q' },
    { value: 0.25, shortcut: 'w' },
    { value: 0.5, shortcut: 'e' },
    { value: 0.75, shortcut: 'r' },
    { value: 1, shortcut: 't' },
  ];

  private _questionsByModelo: QuestionsByModelo[] = [];

  constructor(
    @Inject(DIALOG_DATA) public data: any,

    private _popover: Popover,
    private _keyboard: KeyboardShortcutsService,
    private _assessmentApplicationService: AssessmentApplicationService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    private _alertService: AlertService,
    private _destroyRef: DestroyRef,
    private _assessmentAttemptService: AssessmentAttemptService,
    private _formBuilder: FormBuilder,
    private _store: Store<AppState>,
    private _cdRef: ChangeDetectorRef,
  ) {
    this.initForm();
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(
        take(1),
        takeUntilDestroyed(this._destroyRef),
        notNull(),
        map((activeUser) => first(selectedEntityActive(activeUser))),
        switchMap((entityActive) =>
          combineLatest({
            application: this._assessmentApplicationService
              .show(this.data.applicationUuid, this.form.value)
              .pipe(take(1), takeUntilDestroyed(this._destroyRef), safeEmpty()),
            data: this._assessmentApplicationService
              .getStudent(this.data.applicationUuid, { entity_id: entityActive.id, full: 1 })
              .pipe(take(1), takeUntilDestroyed(this._destroyRef), safeEmpty()),
          }),
        ),
        map(({ application, data }) => ({
          data,
          application: {
            ...application,
            avaliacao: {
              ...application.avaliacao,
              questoes: application.avaliacao.questoes.map((questao, index) => ({ ...questao, index: index + 1 })),
            },
          },
        })),
      )
      .subscribe(({ application, data }) => {
        const students = (data as any).alunos.map((student) => ({
          ...student,
          tentativa_questoes: this.formatTentativaQuestoes(student.tentativa_questoes),
        }));

        this.data$.next({ ...this.data, dataStudent: students });
        this.dataStudents$.next(students);
        this.aplication$.next(application);

        this.onSelectUserById(this.data$.value.userId || first(this.dataStudents$.value)?.usuario_id);
      });

    this.pendingCtrl.valueChanges.pipe(takeUntilDestroyed(_destroyRef)).subscribe((pending) => {
      this.refreshUsersList(pending);
      this.findUserByIndex(0);
    });

    this.dataStudentFilter$ = this.dataStudents$.pipe(
      map((students) => students.filter((student) => !!student.usuario_id && !student.tentativa_uuid)),
    );

    this.selectedUser$.pipe(takeUntilDestroyed(this._destroyRef), notNull()).subscribe((value) => {
      this.setDataViewer(value.data.tentativa_file_url);
    });
  }

  public get hasQuestionProblem(): boolean {
    return this.selectedUser$.value?.data.tentativa_questoes?.some((t) => t.has_problem);
  }

  public get isZoomedIn(): boolean {
    return this.pinchZoomComponent?.isZoomedIn;
  }

  public ngOnDestroy(): void {
    this.data$.complete();
    this.dataStudents$.complete();
    this.selectedUser$.complete();
    this.aplication$.complete();
    this.loading$.complete();
    this.dataViewer$.complete();
  }

  public onCorrectOrSociodemographic(alternative: any, question: any): boolean {
    return (
      (alternative.uuid === question.answer?.alternativa?.uuid && !!alternative.alternativa_correta) ||
      question.sociodemografica === 1
    );
  }

  public onIncorrectAndNotSociodemographic(alternative: any, question: any): boolean {
    return alternative.uuid !== question.answer?.alternativa?.uuid && question.sociodemografica !== 1;
  }

  public onSelectedIncorrectAndNotSociodemographic(alternative: any, question: any): boolean {
    return (
      alternative.uuid === question.answer?.alternativa?.uuid &&
      !alternative.alternativa_correta &&
      question.sociodemografica !== 1
    );
  }

  public shouldApplyWhiteBackground(alternative: any, question: any): boolean {
    return (
      this.onCorrectOrSociodemographic(alternative, question) ||
      this.onSelectedIncorrectAndNotSociodemographic(alternative, question)
    );
  }

  public onForm(control: AbstractControl) {
    control.markAllAsTouched();
  }

  public get evaluationByWeight(): boolean {
    return this.aplication$.value?.avaliacao?.peso_tipo === AssessmentWeightType.Peso;
  }

  public setDataViewer(fileUrl: string) {
    this.dataViewer$.next(fileUrl);
  }

  public initShortcuts() {
    const shortcuts = [
      { key: 'shift + left', command: () => this.onPreviousUser(), preventDefault: true },
      { key: 'shift + right', command: () => this.onNextUser(), preventDefault: true },
      { key: 'shift + n', command: this.focusInputNota, preventDefault: true },
      { key: 'shift + enter', command: this.clickBtnSalvar, preventDefault: true },
      { key: 'esc', command: this.blurInputNota, preventDefault: true },
    ];

    this.scales.forEach((s) => {
      shortcuts.push({
        key: 'shift + ' + s.shortcut,
        command: () => this.clickBtnScale(s.shortcut),
        preventDefault: true,
      });
    });

    this._keyboard.add(shortcuts);
  }

  public onGetAnswer(questaoUuid: string) {
    const answer = this.selectedUser$.value?.data.tentativa_questoes?.find((t) => t.questao?.uuid === questaoUuid);
    return { data: answer };
  }

  public onSaveNota(questionUuid, nota) {
    this.onSaveAll(questionUuid, { nota });
  }

  public onChangeAlternativa(questionUuid: string, alternativaUuid: string) {
    this._alertService
      .confirm({
        title: 'Atenção',
        message: 'Você deseja realmente trocar a alternativa do aluno?',
      })
      .pipe(notNull())
      .subscribe(() => this.onSaveAll(questionUuid, { alternativa_uuid: alternativaUuid }));
  }

  public onSaveAll(questionUuid: string, data: any) {
    this.loadingQuestionAlternative$.next(questionUuid);
    const lastCorrection = this.getLastCorrectionValue();
    const tentativaUuid$ = !this.selectedUser$.value.data.tentativa_uuid
      ? this._assessmentAttemptService
          .store({
            aplicacao_uuid: this.data.applicationUuid,
            user_id: this.selectedUser$.value.data.usuario_id,
            modo_rand: this.form.value.modo_rand,
          })
          .pipe(
            take(1),
            safeEmpty(),
            notNull(),
            map((data) => data.uuid),
          )
      : of(this.selectedUser$.value.data.tentativa_uuid);

    tentativaUuid$
      .pipe(
        switchMap((tentativaUuid: string) =>
          this._assessmentApplicationService
            .printedCorrection(tentativaUuid, questionUuid, {
              ...data,
              last: lastCorrection,
            })
            .pipe(safeEmpty(), notNull()),
        ),
      )
      .subscribe((data) => {
        this.updateStudentData(data, questionUuid);
        this._notificationService.success(this._translateService.instant('geral.salvo-com-sucesso'));
        this.refreshUsersList(this.pendingCtrl.value);
        this.loadingQuestionAlternative$.next(undefined);
      });
  }

  public onSelectUserById(userId: number) {
    const index = this.data$.value.dataStudent.findIndex((a) => a.usuario_id === userId);

    this.onSetSelectedUser(this.data$.value.dataStudent[index], index);
    this.findUserByIndex(index);
  }

  public toLetter(index: number): string {
    return String.fromCharCode(65 + index);
  }

  public onPreviousUser() {
    const index =
      (this.selectedUser$.value.index - 1 + this.data$.value.dataStudent.length) % this.data$.value.dataStudent.length;
    this.findUserByIndex(index);
  }

  public onCalcNota(nota, scale) {
    const result = nota * scale;
    const notaResult = result.toFixed(2);
    return { nota: parseFloat(notaResult) };
  }

  public onNextUser() {
    const index = (this.selectedUser$.value.index + 1) % this.data$.value.dataStudent.length;
    this.findUserByIndex(index);
  }

  public onSetSelectedUser(user: any, index: number) {
    const data = !!user ? { data: user, index } : undefined;
    this.selectedUser$.next(data);
    if (!!this.aplication$.value?.embaralhar_questoes || !!this.aplication$.value?.embaralhar_alternativas) {
      this.form.patchValue({ modo_rand: user.tentativa_modo_rand }, { emitEvent: false });
      this.setQuestionsByModelo(user.tentativa_modo_rand);
    }
  }

  public onGetQuestionsWithAnswers() {
    const questions = this.aplication$.value.avaliacao.questoes.map((question) => ({
      ...question,
      answer: this.selectedUser$.value.data?.tentativa_questoes?.find((t) => t.questao?.uuid === question.uuid),
    }));

    return {
      list: questions.filter((question) => !question?.answer?.has_problem),
      problems: questions.filter((question) => !!question?.answer?.has_problem),
    };
  }

  public onOpenPopover(elementRef: ElementRef, question: any): void {
    this._popover
      .open<any>(PopoverAnswerOptionsComponent, {
        positionStrategy: [Strategy.LEFT],
        elementRef,
        data: {
          aplicacao_uuid: this.data.applicationUuid,
          question,
          tentativa_uuid: this.selectedUser$.value?.data.tentativa_uuid,
        },
      })
      .closed.pipe(filter((result) => !!result))
      .subscribe(({ action, data }) => {
        const options = {
          [AnswerOptionEnum.Anull]: () => this.updateStudentData(data, question.uuid),
          [AnswerOptionEnum.Clean]: () => this.onCleanAlternative(data),
        };

        options[action]();
      });
  }

  public onCleanAlternative(questionUuid: string) {
    this._alertService
      .confirm({
        title: 'Atenção',
        message: 'Você deseja realmente definir questão em branco?',
      })
      .pipe(take(1), notNull())
      .subscribe(() => this.onSaveAll(questionUuid, { alternativa_uuid: null, em_branco: 1 }));
  }

  public onOpenAnullQuestion(elementRef: ElementRef, question: any) {
    this._popover
      .open(PopoverAnnulQuestionComponent, {
        positionStrategy: [Strategy.LEFT],
        elementRef,
        data: {
          aplicacao_uuid: this.data.applicationUuid,
          question,
          tentativa_uuid: this.selectedUser$.value?.data.tentativa_uuid,
          type: AssessmentAnnulTypeEnum.Answer,
        },
      })
      .closed.subscribe((data) => this.updateStudentData(data, question.uuid));
  }

  public onRemoveStudent(student: any, index: number) {
    this._alertService
      .confirm({
        title: 'Atenção',
        message: 'Você deseja realmente desvincular este aluno da resposta?',
      })
      .pipe(
        take(1),
        notNull(),
        switchMap(() =>
          this._assessmentApplicationService
            .noStudentslinked(this.selectedUser$.value?.data.tentativa_uuid, undefined)
            .pipe(safeEmpty(), take(1)),
        ),
      )
      .subscribe(() => {
        const newStudent = first(
          this.data$.value.dataStudent
            .filter((s) => s.usuario_id === student.usuario_id)
            .map((s) => ({
              ...s,
              tentativa_uuid: undefined,
              tentativa_questoes: [],
              tentativa_qrcode: undefined,
              tentativa_proficiencia: [],
              tentativa_modo_rand: undefined,
              tentativa_file_url: undefined,
              data_fim: undefined,
              data_inicio: undefined,
              status: undefined,
            })),
        );
        const updatedStudents = this.data$.value.dataStudent.map((s, index) => {
          if (this.selectedUser$.value?.data.usuario_id === s.usuario_id) {
            const newTentativa = {
              ...s,
              nome: undefined,
              usuario_id: undefined,
              turma_descricao: undefined,
            };

            return newTentativa;
          }
          return s;
        });

        updatedStudents.splice(index, 0, newStudent);

        this.data$.next({ ...this.data$.value, dataStudent: updatedStudents });
        this.onSetSelectedUser(newStudent, index);
        this.refreshUsersList(false);
      });
  }

  public onRemoveAttempt(student: any) {
    this._alertService
      .confirm({
        title: 'Atenção',
        message: 'Você deseja realmente excluir esta tentativa?',
      })
      .pipe(
        take(1),
        notNull(),
        switchMap(() =>
          this._assessmentAttemptService
            .destroy(this.selectedUser$.value?.data.tentativa_uuid)
            .pipe(safeEmpty(), take(1)),
        ),
      )
      .subscribe(() => {
        let updatedStudents = [];
        if (!!student.usuario_id) {
          student = {
            ...student,
            tentativa_uuid: undefined,
            tentativa_questoes: [],
            tentativa_qrcode: undefined,
            tentativa_proficiencia: [],
            tentativa_modo_rand: undefined,
            tentativa_file_url: undefined,
            data_fim: undefined,
            data_inicio: undefined,
            status: null,
          };

          updatedStudents = this.data$.value.dataStudent.map((s, index) => {
            if (this.selectedUser$.value?.data.usuario_id === s.usuario_id) {
              this.onSetSelectedUser(student, index);
              return student;
            }
            return s;
          });
        } else {
          updatedStudents = this.data$.value.dataStudent.filter((s) => s.tentativa_uuid !== student.tentativa_uuid);
          this.onNextUser();
        }

        this.data$.next({ ...this.data$.value, dataStudent: updatedStudents });
        // this.onSetSelectedUser(newStudent, index);
        this.refreshUsersList(false);
      });
  }

  public onVincularStudent(student: any) {
    if (!student) {
      return;
    }

    this._alertService
      .confirm({
        title: 'Atenção',
        message: 'Você deseja realmente vincular esse aluno para a questão?',
      })
      .pipe(
        take(1),
        tap((result) => {
          if (!result) {
            this.dataStudentCtrl.reset();
          }
        }),
        notNull(),
        switchMap(() =>
          this._assessmentApplicationService
            .noStudentslinked(this.selectedUser$.value?.data.tentativa_uuid, student?.usuario_id)
            .pipe(safeEmpty(), take(1)),
        ),
      )
      .subscribe(() => {
        this.dataStudentCtrl.reset();
        const updatedStudents = this.data$.value.dataStudent
          .filter((s) => s.usuario_id !== student.usuario_id)
          .map((s, index) => {
            if (this.selectedUser$.value?.data.tentativa_uuid === s.tentativa_uuid) {
              const newStudent = {
                ...s,
                nome: student.nome,
                usuario_id: student.usuario_id,
                turma_descricao: student.turma_descricao,
              };
              this.onSetSelectedUser(newStudent, index);
              return newStudent;
            }
            return s;
          });
        this.data$.next({ ...this.data$.value, dataStudent: updatedStudents });
        this.refreshUsersList(false);
      });
  }

  public onToggleZoom() {
    this.pinchZoomComponent.toggleZoom();
  }

  public onCancelAnull(event: any, questionUuid: string) {
    this.updateStudentData(event, questionUuid);
  }

  public trackByUuid = (index: any, data: any) => data.uuid;

  private findUserByIndex(index: number) {
    const user = this.dataStudents$.value[index];
    if (user) {
      setTimeout(
        () => document.getElementById('user_' + user.tentativa_uuid)?.scrollIntoView({ behavior: 'smooth' }),
        100,
      );
    }
    this.onSetSelectedUser(user, index);
  }

  private updateStudentData(data: any, questionUuid: string): void {
    const tentativaQuestao = this.formatTentativaQuestoes(data.tentativa_questoes)?.find(
      (tq) => tq?.questao?.uuid === questionUuid,
    );

    const updatedStudents = this.data$.value.dataStudent.map((a) => {
      if (
        (!!this.selectedUser$.value.data.tentativa_uuid &&
          a.tentativa_uuid === this.selectedUser$.value.data.tentativa_uuid) ||
        (!!this.selectedUser$.value.data.usuario_id && a.usuario_id === this.selectedUser$.value.data.usuario_id)
      ) {
        return {
          ...a,
          tentativa_uuid: data.tentativa_uuid || a.tentativa_uuid,
          tentativa_modo_rand: data.tentativa_modo_rand || a.tentativa_modo_rand,
          status: data.status,
          nota_calculada: data.nota_calculada,
          tentativa_questoes: a.tentativa_questoes?.some((tq) => tq.questao.uuid === questionUuid)
            ? a.tentativa_questoes.map((aq) => (aq.questao.uuid === questionUuid ? tentativaQuestao : aq))
            : [...(a.tentativa_questoes || []), tentativaQuestao],
        };
      }
      return a;
    });
    this.data$.next({ ...this.data$.value, dataStudent: updatedStudents });
    this.refreshUsersList(this.pendingCtrl.value);
    this.findUserByIndex(this.selectedUser$.value.index);
  }

  private refreshUsersList(pending: boolean) {
    const users = !!pending
      ? this.data$.value.dataStudent.filter(
          (a) => !a.tentativa_questoes || a.tentativa_questoes.some((t) => isNil(t.nota)),
        )
      : this.data$.value?.dataStudent;

    this.dataStudents$.next(users);
  }

  private getLastCorrectionValue(): number {
    const student = this.data$.value.dataStudent.find(
      (a) => a.tentativa_uuid === this.selectedUser$.value.data.tentativa_uuid,
    );
    const numTentativasSemNota = student?.tentativa_questoes?.filter((t) => isNil(t.nota))?.length || 0;
    return numTentativasSemNota < 2 ? 1 : 0;
  }

  private focusInputNota() {
    const inputNota = document.getElementById('inputNota') as HTMLInputElement;
    if (inputNota) {
      inputNota.focus();
      if (inputNota.tagName === 'INPUT') {
        inputNota.select();
      }
    }
  }

  private clickBtnSalvar() {
    const btn = document.getElementById('btnSalvar') as HTMLButtonElement;
    if (btn) {
      btn.click();
    }
  }

  private blurInputNota() {
    const inputNota = document.getElementById('inputNota') as HTMLInputElement;
    if (inputNota) {
      inputNota.blur();
    }
  }

  private clickBtnScale(shortcut: string) {
    const btn = document.getElementById('btn_' + shortcut) as HTMLButtonElement;
    if (btn) {
      btn.click();
    }
  }

  private setQuestionsByModelo(modoRand: number) {
    const questionsByModelo = this._questionsByModelo.find((item) => item.modo_rand === modoRand);
    this.aplication$.next({
      ...this.aplication$.value,
      avaliacao: { ...this.aplication$.value.avaliacao, questoes: questionsByModelo?.questions || [] },
    });

    if (!modoRand || !!questionsByModelo?.questions?.length) {
      return;
    }

    this.loadingQuestions$.next(true);
    this._assessmentApplicationService
      .questions(this.data$.value.applicationUuid, modoRand)
      .pipe(
        safeEmptyList(),
        take(1),
        finalize(() => this.loadingQuestions$.next(false)),
        map((data) => data.map((question: any, index) => ({ ...question, index: index + 1 }))),
      )
      .subscribe((data) => {
        this.aplication$.next({
          ...this.aplication$.value,
          avaliacao: { ...this.aplication$.value.avaliacao, questoes: data },
        });

        this._questionsByModelo.push({ modo_rand: modoRand, questions: data });
      });
  }

  private formatTentativaQuestoes(tentativaQuestoes: any): any[] {
    return tentativaQuestoes?.map((t) => ({
      ...t,
      has_problem: t.questao?.tipo === 0 && !t.alternativa?.uuid && t.em_branco === 0 && t.anulado === 0,
    }));
  }

  private initForm() {
    this.form = this._formBuilder.group({
      modo_rand: [undefined],
    });

    this.form.controls.modo_rand.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap(() => this.loadingQuestions$.next(false)),
        tap(() =>
          this.aplication$.next({
            ...this.aplication$.value,
            avaliacao: { ...this.aplication$.value.avaliacao, questoes: [] },
          }),
        ),
        filter((modoRand) => !!modoRand),
        tap(() => this.loadingQuestions$.next(true)),
        switchMap((modoRand) =>
          combineLatest({
            tentativa: !!this.selectedUser$.value.data.tentativa_uuid
              ? this._assessmentAttemptService
                  .update(this.selectedUser$.value.data.tentativa_uuid, { modo_rand: modoRand })
                  .pipe(safeEmpty(), take(1))
              : of(undefined),
            modoRand: of(modoRand),
          }),
        ),
      )
      .subscribe((data) => {
        this.loadingQuestions$.next(false);
        if (!!data.tentativa) {
          const updatedStudents = this.data$.value.dataStudent.map((student, index) => {
            if (student.tentativa_uuid === this.selectedUser$.value.data.tentativa_uuid) {
              student = {
                ...student,
                ...data.tentativa,
                tentativa_questoes: this.formatTentativaQuestoes(data.tentativa.tentativa_questoes),
              };
              this.onSetSelectedUser(student, index);
            }

            return student;
          });
          this.data$.next({ ...this.data$.value, dataStudent: updatedStudents });
          this.refreshUsersList(false);
        }

        this.setQuestionsByModelo(data.modoRand);
      });
  }
}
