<div
  class="main-header relative z-200 grid gap-x-4 border-b-2 border-theme-500 bg-white shadow-md shadow-black/12 transition-all duration-300"
  *ngIf="store$ | async as store"
>
  <ng-container *ngIf="{ isExternal: isExternal$ | async, isExternalHispanic: isExternalHispanic$ | async } as client">
    <!-- Adventist logo -->
    <button
      app-icon-button
      type="button"
      class="main-header__adventist relative z-10 !rounded-none !rounded-br-2xl bg-base-ea-500 text-white shadow-sm shadow-black/40 lg:px-6"
      [ngClass]="{
        'bg-base-weekly-500': client.isExternal,
        'bg-[#3E3E90]': client.isExternalHispanic,
        'bg-base-ea-500': !client.isExternal && !client.isExternalHispanic,
      }"
      [routerLink]="'/'"
    >
      <ng-container *ngIf="client.isExternal || client.isExternalHispanic; else eaTemplate">
        <svg-icon *ngIf="client.isExternal" src="assets/images/logos/logo-cpb.svg" class="w-7"></svg-icon>
        <svg-icon *ngIf="client.isExternalHispanic" src="assets/images/logos/logo-aces.svg" class="w-12"></svg-icon>
      </ng-container>

      <ng-template #eaTemplate>
        <svg-icon src="assets/images/logos/logo-ea.svg" class="w-7"></svg-icon>
      </ng-template>
    </button>

    <!-- E-class logo-->
    <a app-icon-button class="main-header__eclass hidden lg:inline-flex" [routerLink]="'/'">
      <ng-container *ngIf="client.isExternal || client.isExternalHispanic; else eclassTemplate">
        <svg-icon *ngIf="client.isExternal" src="assets/images/logos/logo-weekly.svg" class="w-16 lg:w-24"></svg-icon>
        <svg-icon
          *ngIf="client.isExternalHispanic"
          src="assets/images/logos/logo-acesclass.svg"
          class="w-16 lg:w-28"
        ></svg-icon>
      </ng-container>
      <ng-template #eclassTemplate>
        <svg-icon src="assets/images/logos/logo-eclass.svg" class="w-16 lg:w-24"></svg-icon>
      </ng-template>
    </a>
  </ng-container>

  <!-- Current entity -->
  <div
    class="main-header__entity my-2 flex flex-col justify-center truncate border-base-gray-100 pl-4 align-middle lg:border-l"
    *ngIf="store?.usuario_ativo as activedUser"
  >
    <h1 class="truncate text-xl font-semibold leading-none">
      {{ activedUser.titulo_entidade || activedUser.nome_escola }}
    </h1>
    <span class="font-normal text-base-gray-400">
      {{ activedUser.tipo_entidade }}
    </span>
  </div>

  <!-- Bilingual -->
  <ng-container *ngIf="{ languages: language$ | async, current: userLanguage$ | async } as lang">
    <div class="main-header__bilingual flex items-center justify-center gap-1">
      <div *ngFor="let language of lang.languages" class="w-8">
        <img
          class="flag cursor-pointer rounded-lg opacity-50 transition-all duration-300 hover:scale-110 hover:opacity-100 hover:shadow-lg"
          [ngClass]="{ '!opacity-100': isEquals(language, lang.current) }"
          [src]="language.img_flag"
          [alt]="language.nome"
          (click)="onLang(language, lang.current)"
        />
      </div>
    </div>

    <!-- Dependent -->
    <div
      *ngIf="dependent(store) as dependent"
      class="main-header__dependent my-2 flex flex-col justify-center truncate border-l border-base-gray-100 pl-4 align-middle lg:!col-start-auto"
      [ngClass]="{ '!col-start-2': lang.languages | isEmpty }"
    >
      <h1 class="truncate text-md">{{ dependent | orderName | async }}</h1>
      <span class="font-normal leading-none text-base-gray-400"> {{ dependent.serie || '-' }} </span>
    </div>
  </ng-container>

  <!-- Service -->
  <div *isAllow="[1, 8]" class="col-start-4 flex w-full justify-end p-2 lg:!col-start-auto">
    <button
      type="button"
      app-tooltip
      app-outline-button
      [tooltip]="'geral.servicos' | translate"
      (click)="onService()"
      class="group !rounded-3xl border-none uppercase text-theme-500 hover:bg-theme-500 md:border-solid md:border-theme-500 md:px-4"
    >
      <span class="hidden pr-3 text-md transition duration-300 group-hover:text-theme-contrast md:block" translate>
        geral.servicos
      </span>
      <svg-icon
        class="h-7 w-7 text-theme-500 transition duration-300 group-hover:text-theme-contrast"
        src="assets/icons/icon-access.svg"
      ></svg-icon>
    </button>
  </div>

  <!-- Notification -->
  <button
    #notification
    *ngIf="isDev"
    type="button"
    app-icon-button
    class="main-header__notification relative"
    (click)="onNotification(notification)"
  >
    <ng-container *ngIf="{ show: hasNotification$ | async } as notification">
      <span *ngIf="notification.show" class="absolute right-[10px] top-[17px] flex h-4 w-4">
        <span
          [ngClass]="{ 'animate-ping': !!pulseNotification }"
          class="absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"
        ></span>
        <span class="relative inline-flex h-4 w-4 rounded-full bg-red-500"></span>
      </span>
    </ng-container>
    <svg-icon class="h-7 w-7 text-theme-500" src="assets/icons/icon-bell.svg"></svg-icon>
  </button>

  <!-- Toogle -->
  <button type="button" *ngIf="showToogle" app-icon-button class="main-header__toogle md:hidden" (click)="onToogle()">
    <svg-icon class="h-7 w-7 text-theme-500" [src]="icon(toogleStatus)"></svg-icon>
  </button>

  <!-- Profile -->
  <div class="main-header__profile flex items-center justify-end p-2">
    <button
      id="menu"
      #menu
      app-icon-button
      app-outline-button
      type="button"
      class="border-1 w-max !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40"
      (click)="onMenu(menu)"
    >
      <app-user-photo class="h-10 w-10" [src]="store.photo"></app-user-photo>
      <span class="absolute bottom-0 right-0 h-4 w-4 rounded-full border-2 border-white bg-green-500"></span>
    </button>
  </div>

  <!-- Divider -->
  <hr class="main-header__divider -m-2 h-2 border-none shadow-md shadow-gray-500/20 lg:hidden" />
</div>

<!-- Float main menu -->
<app-main-menu
  class="absolute bottom-[calc((var(--vh)_*_-100)_+_10rem)] flex w-full justify-center transition duration-300 lg:top-1/2"
></app-main-menu>
