import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentApplicationMode, AssessmentManagedType, FunctionsEnum } from '@app/core/models';
import { ModalCreateResponseCardComponent } from '@app/modules/assessments-manager/shared/modal-create-response-card/modal-create-response-card.component';
import { ModalUploadFilesComponent } from '@app/modules/assessments-manager/shared/modal-upload-files/modal-upload-files.component';
import { notNull, selectedEntityActive } from '@app/shared';
import { POPOVER_DATA } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first, isEqual } from 'lodash';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { PopoverRef } from '../../../../../../../shared/modules/template/components/popover/index';
import { ModalAnswersAuditComponent } from '../../../modal-answers-audit/modal-answers-audit.component';
import { ModalAnswersComponent } from '../../../modal-answers/modal-answers.component';
import { ModalAssessmentsPrintSettingsComponent } from '../../../modal-assessments-print-settings/modal-assessments-print-settings.component';
import { ModalAssessmentsImportFileComponent } from '../modal-assessments-import-file/modal-assessments-import-file.component';
import { ModalExportFileComponent } from '../modal-export-file/modal-export-file.component';

@Component({
  selector: 'app-popover-options-button',
  templateUrl: './popover-options-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverOptionsButtonComponent {
  public readonly assessmentApplicationMode = AssessmentApplicationMode;
  public entityActive: any;
  public canPreview = false;

  constructor(
    @Inject(POPOVER_DATA) public data: any,
    private _dialog: Dialog,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
    private _popoverRef: PopoverRef,
  ) {
    combineLatest([
      this._store.select(AppSelectors.ActiveUser).pipe(takeUntilDestroyed(this._destroyRef), notNull()),
      this._store
        .select(AppSelectors.DefaultFunction)
        .pipe(takeUntilDestroyed(this._destroyRef), notNull(), distinctUntilChanged(isEqual)),
    ]).subscribe(([activeUser, defaultFunction]) => {
      this.entityActive = first(selectedEntityActive(activeUser));
      this.canPreview =
        this.data.application.previewer ||
        this.data.application.criado_por === activeUser.usuario_id ||
        this.permissionView() ||
        [FunctionsEnum.desenvolvedorPortal].includes(defaultFunction);
    });
  }

  public get isPAAEB(): boolean {
    return [AssessmentManagedType.PAAEB, AssessmentManagedType.PAEE].includes(
      this.data.application.avaliacao.tipo_gerenciada,
    );
  }

  public get isDivision(): boolean {
    return this.entityActive?.tipo === 5;
  }

  public get isEntity(): boolean {
    return this.entityActive?.tipo === 1;
  }

  public onModalResolutionEvaluation() {
    this._dialog
      .open(ModalAnswersComponent, {
        data: {
          applicationUuid: this.data.application.uuid,
          evaluation: this.data.application.avaliacao,
        },
        disableClose: true,
        hasBackdrop: true,
      })
      .closed.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this._popoverRef.close(true));
  }

  public onModalAnswersAuditComponent() {
    this._dialog
      .open<boolean>(ModalAnswersAuditComponent, {
        data: {
          applicationUuid: this.data.application.uuid,
          evaluation: this.data.application.avaliacao,
        },
        disableClose: true,
        hasBackdrop: true,
      })
      .closed.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this._popoverRef.close(true));
  }

  public onAssessmentsPrintSettingsComponent() {
    this._dialog.open<boolean>(ModalAssessmentsPrintSettingsComponent, {
      data: {
        application_uuid: this.data.application.uuid,
        shuffle_questions: this.data.application.embaralhar_questoes,
      },
    });
  }

  public onAssessmentsImportFileComponent() {
    this._dialog.open<boolean>(ModalAssessmentsImportFileComponent, {
      data: {
        application_uuid: this.data.application.uuid,
      },
    });
  }

  public onUploadFiles() {
    this._dialog.open(ModalUploadFilesComponent, {
      data: {
        application_uuid: this.data.application.uuid,
        tipo: this.data.application.avaliacao.tipo,
      },
    });
  }

  public onCreateResponseCard() {
    this._dialog.open(ModalCreateResponseCardComponent, {
      data: {
        application_uuid: this.data.application.uuid,
        dataStudent: this.data.dataStudent,
      },
    });
  }

  public onExportFile() {
    this._dialog.open(ModalExportFileComponent, {
      data: this.data.application.uuid,
    });
  }

  private permissionView(): boolean {
    const options = {
      [1]: () => 'entidade_id',
      [2]: () => 'campo_id',
      [3]: () => 'uniao_id',
      [4]: () => 'editora_id',
      [5]: () => 'divisao_id',
    };
    const existePermissao = this.data.application.avaliacao.entidade_permissao.some(
      (permissao) => permissao[options[this.entityActive.tipo]()] === this.entityActive.id,
    );
    return existePermissao;
  }
}
